<template>
  <div class="app-login loginClass">
    <div class="login-wrapper loginClass1">
      <el-form ref="loginForm" :model="login" :rules="rules" class="login-form zero-form" label-position="left" label-width="auto">
        <el-form-item :label="$t('ps.find.login.code')" prop="username">
<!--          <el-autocomplete style="width: 100%"-->
<!--                           :fetch-suggestions="querySearch"-->
<!--              @select="handleSelect"-->
<!--                  v-model="login.username"-->
<!--                    :placeholder="$t('login.username.placeholder')">-->
<!--          </el-autocomplete>-->
            <el-input
                v-model.trim="login.username"
                @input="value=>login.username=value.toUpperCase()"
                :placeholder="$t('login.username.placeholder')">
            </el-input>
        </el-form-item>
        <el-form-item :label="$t('mi.member.pwd')" prop="password">
          <el-input
                  v-model.trim="login.password" show-password
                    :placeholder="$t('login.password.placeholder')">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('sysUser.validateCode')" prop="code">
          <div class="vcode">
            <el-input v-model.trim="login.code" maxlength="4"
                      :placeholder="$t('login.verificationcode.placeholder')"
                      @keyup.enter.native="handleLogin">
            </el-input>
            <el-image fit="contain" :src="captcha.data" @click="handleRefresh">
              <div slot="error" class="image-slot" @click="handleRefresh">
                <i v-if="captchaLoading" class="el-icon-loading"></i>
                <i v-else class="el-icon-refresh"></i>
              </div>
              <div slot="placeholder" class="image-slot"><i class="el-icon-loading"></i></div>
            </el-image>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" :loading="loginLoading" @click="handleLogin">
            {{ $t('operation.button.login') }}
          </el-button>
        </el-form-item>
        <p style="cursor: pointer;" @click="forgotpassword">{{$t('forgot.password')}}?</p>
      </el-form>
    </div>
    <div class="loginClass2">
      <p>Entrar el usuario y la contraseña inicial en el correo electrónico de bienvenida para iniciar sesión por primera vez. </p>
      <p>El negocio de Whieda comienza a partir de ahora.</p>
    </div>
    <div class="loginClass3"></div>
  </div>
</template>

<script>
import {fetchCaptcha} from '@/api/home'
import router from "@/router"
import store from '@/store'
import { mapGetters } from "vuex";
// import Cookies from "js-cookie";
// import {getLanguage} from "@/lang";


export default {
  name: 'Login',
  data() {
    var validatorUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('login.id.input') ? this.$t('login.id.input') : this.$t('login.id.input')))
      } else {
        callback()
      }
    }
    var validatorPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register.us.select.password') ? this.$t('register.us.select.password') : this.$t('login.pass.input')))
      } else {
        callback()
      }
    }
    var validatorCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('me.inputyzm') ? this.$t('me.inputyzm') : this.$t('me.inputyzm')))
      } else {
        callback()
      }
    }
    return {
      restaurants: [],
      timeout:  null,
      activeName: 'agent',
      radio: 'true',
      login: {
        username: undefined,
        password: undefined,
        code: undefined
      },
      rules: {
        username: [{validator: validatorUsername, trigger: 'blur'}],
        password: [{validator: validatorPassword, trigger: 'blur'}],
        code: [{validator: validatorCode, trigger: 'blur'}],
      },
      loginLoading: false,
      captchaLoading: false,
      captcha: {
        data: '',
        key: ''
      },
      redirect: undefined,
      otherQuery: {},
      mobile: '',
      sendLoading: false,
      send: false,
      time: 60
    }
  },
  computed: {
    ...mapGetters([
        'language',
        'ckCode'
    ]),
    isAgent() {
      return this.radio === 'true'
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    },
    // 'login.username':{
    //   handler(newValue){
    //     if(newValue.length >= 3){
    //       this.restaurants = []
    //       let list = ['aaaa','bbbbb','cccc']
    //       list.forEach(v=>{
    //         let obj = { 'value': v}
    //         this.restaurants.push(obj)
    //       })
    //       console.log(this.restaurants,'list')
    //     }else{
    //       this.restaurants = []
    //     }
    //   }
    // }
    // "login.username": function (val) {
    //   this.login.username = val.toUpperCase()
    // }
  },
  async mounted() {
    this.getCaptcha()
    // switch (Cookies.get('language')) {
    //   case 'zh_CN':
    //     this.selectedlanguage = {code: 'zh_CN', title: 'Chinese 中文'}
    //     break
    //   case 'en_GB':
    //     this.selectedlanguage = {code: 'en_GB', title: 'English 英语'}
    //     break
    //   case 'ru_RU':
    //     this.selectedlanguage = {code: 'ru_RU', title: 'Русский 俄语'}
    //     break
    //   case 'mn_MN':
    //     this.selectedlanguage = {code: 'mn_MN', title: 'Монгол хэл  蒙古语'}
    //     break
    //   case 'kk_KZ':
    //     this.selectedlanguage = {code: 'kk_KZ', title: 'Қазақша 哈萨克语'}
    //     break
    //   default:
    //     this.selectedlanguage = {code: 'zh_CN', title: 'Chinese 中文'}
    // }
    // this.$i18n.locale = this.selectedlanguage.code
    // await this.$store.dispatch('app/setLanguage', this.selectedlanguage.code)
    // getLanguage()
    // await this.getCountry(this.companyCode)
  },
  created() {
    // this.getYuyan()
  },
  methods: {
    // async getCountry(country) {
    //   if (this.token) {
    //     await this.$store.dispatch('user/setCkCode', country)
    //   }
    // },
    // getYuyan(){
    // var jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
    //   let lang = ''
    //   if(jsSrc.indexOf('zh')!=-1 || jsSrc.indexOf('zh-cn')!=-1) {
    //     // 浏览器语言是中文
    //     lang  = 'zh_CN'
    //   }
    //   else if(jsSrc.indexOf('en')!=-1) {
    //     // 浏览器语言是英文
    //     lang  = 'en_GB'
    //   }
    //   else if(jsSrc.indexOf('kk')!=-1 || jsSrc.indexOf('kk-KZ')!=-1){
    //     lang  = 'kk_KZ'
    //   }
    //   else if(jsSrc.indexOf('mn')!=-1 || jsSrc.indexOf('mn-MN')!=-1){
    //     lang  = 'mn_MN'
    //   }
    //   else{
    //     lang  = 'ru_RU'
    //   }
    //   this.$i18n.locale = lang
    //   this.$store.dispatch('app/setLanguage', lang)
    //   getLanguage()
    // },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toUpperCase().indexOf(queryString.toUpperCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    getCaptcha() {
      this.captchaLoading = true
      this.captcha = {
        data: '',
        key: ''
      }
      fetchCaptcha().then(response => {
        this.captcha = response.data
        this.captchaLoading = false
      }).catch(err => {
        console.warn(err)
        this.captchaLoading = false
      })
    },
    handleRefresh() {
      if (!this.captchaLoading) {
        this.getCaptcha()
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const loginData = {
            userCode: this.login.username,
            password: this.login.password,
            captcha: this.login.code,
            captchaKey: this.captcha.key,
            smsCode: this.login.smsCode,
            calcCompanyCode: this.ckCode,
            isAgent: false
          }
          this.loginLoading = true
          this.$store.dispatch('user/login', loginData).then(async modules => {
            const res = await store.dispatch('user/getInfo')
            if (res.btnCancel) {
              this.getCaptcha()
            }
            const accessRoutes = await store.dispatch('permission/generateRoutes', res.modules)

            router.addRoutes(accessRoutes)
            if(modules.data.hasEmail === false){
              await this.$router.push({name:'EditInformation'})
            }else{
              await this.$router.push({path: this.redirect || '/personal-center/index', query: this.otherQuery})
            }
            // await this.$router.push({path: this.redirect || '/personal-center/index', query: this.otherQuery})
            this.loginLoading = false
          }).catch(() => {
            this.loginLoading = false
          })
        }
      })
    },
    forgotpassword(){
      this.$router.push({path:'/forgotpassword'})
    }
  }
}
</script>

<style lang="scss" scoped>
.loginClass{
  display: flex;
}
.loginClass1{
  //width: 450px;
  flex: 1;
}
.loginClass3{
  //width: 450px;
  flex: 1;
}
.loginClass2{
  flex: 1;
  padding-left: 20px;
  p{
    text-align: left;
    font-size: 18px;
    color: #266AB5;
  }
}
.zero-form {
    .el-form-item__label {
        font-weight: 700;
    }
}

.el-tabs__item.is-active {
  color: #F39F43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.btn {
  text-align: center;
  border-bottom: 1px solid #DFE1E8;
}
@media screen and (max-width: 1000px) {
  ::v-deep.el-form-item__content{
    height: 55px!important;
  }
  .main-container {
  }
  .app-main{
    min-height: 400px;
    height: 100%;
  }
  .app-login{
    min-height: 400px;
    position: relative;
  }
  .login-wrapper{
    //transform: scale(1.8);
    height: 300px;
    width: 100%!important;
    margin: 0!important;
  }
}
</style>
